import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import window from "global"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default function BlogDetail({ data }) {
  const { markdownRemark, allMarkdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  let postIndex = 0
  allMarkdownRemark.edges.map((item, index) => {
    if (item.node.frontmatter.path === frontmatter.path) {
      if (allMarkdownRemark.edges.length === index + 1) {
        postIndex = 0
      } else {
        postIndex = index + 1
      }
    }
  })
  const nextPost = allMarkdownRemark.edges[postIndex].node.frontmatter
  
  return (
    <Layout>
      <SEO
        title={
          frontmatter.title
        }
        description={frontmatter.description}
        // meta={post.yoast.meta}
      />
      <div className="container blog-detail blog ">
        <div className="post py60" style={{marginTop:0}}>
          <div className="col-md-5">
            <span className="post-category">BLOG</span>
            <span>{frontmatter.categories}</span>
            <h1>{frontmatter.title}</h1>
            <p
              dangerouslySetInnerHTML={{
                __html: frontmatter.topDetail,
              }}
            ></p>
            <div className="author">
              <Img
                alt={frontmatter.authorName}
                fixed={frontmatter.authorImage.childImageSharp.fixed}
              />
              <div className="author-name">
                <b>{frontmatter.authorName}</b>
              </div>
            </div>
          </div>
          <div className="col-md-5 post-image">
            <Img
              fixed={frontmatter.featuredImage.childImageSharp.fixed}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
        </div>
        <div className="content">
          <div
            id="rootContent"
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>

        <div style={{ textAlign: "center", padding: "50px 0px" }}>
          <button
            className="red-button"
            onClick={() => {
              window.location.href = "/blog"
            }}
          >
            Go Back
          </button>
        </div>

        <div className="author-end py60">
          <Img
            alt={frontmatter.authorName}
            fixed={frontmatter.authorImage.childImageSharp.fixed}
          />
          <div className="author-end_name">
            <small>Author</small>
            <b className="name">{frontmatter.authorName}</b>
          </div>
        </div>
        <div className="next-article" style={{ marginBottom: "100px" }}>
          <div className="post">
            <div className="col-md-5">
              <span>Next Article</span>
              <h2>
                <a href={nextPost.path}>{nextPost.title}</a>
              </h2>
              <p>{nextPost.topDetail}</p>
              <div className="author">
                <Img
                  alt={nextPost.authorName}
                  fixed={nextPost.authorImage.childImageSharp.fixed}
                />
                <div className="author-name">
                  <b>{nextPost.authorName}</b>
                </div>
              </div>
            </div>
            <div className="col-md-5 post-image">
              <Img
                fixed={nextPost.featuredImage.childImageSharp.fixed}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
        </div>

        {/* <div className="related-article py60">
                        <h2>{post.categories[0].name} <br/>ile ilgili içerikler.</h2>
                        <div className="related-article_posts">
                            {allWordpressPost.edges.map(({ node }, i) => 
                                node.categories.filter((category) => { return category.name === post.categories[0].name && node.id !== post.id; }).length > 0 && i < 2 ? (
                                    <div className="col-md-5 post">
                                        <img className="postimg" alt="alt text" src={(node.featured_media || {}).source_url || ''} />
                                        <span>{node.categories[0].name}</span>
                                        <h2><a href={'/blog/' + node.slug}>{node.title}</a></h2>
                                        <p dangerouslySetInnerHTML={{__html: node.excerpt.replace(/<p>/, '').replace(/<\/p>/g, '').replace('[&hellip;]', '[...]')}}></p>
                                        <div className="author">
                                            <img alt="alt text" src={node.author.avatar_urls.wordpress_96} />
                                            <div className="author-name">
                                                <b>{node.author.name}</b>
                                                <p>{node.author.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            )}
                        </div>
                    </div> */}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        description
        topDetail
        categories
        authorName
        authorImage {
          childImageSharp {
            fixed(height: 60) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        featuredImage {
          childImageSharp {
            fixed(width: 415, height: 375) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: { frontmatter: { categories: { eq: "Genel" } } }
    ) {
      edges {
        node {
          frontmatter {
            path
            title
            topDetail
            categories
            authorName
            authorImage {
              childImageSharp {
                fixed(height: 60) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            featuredImage {
              childImageSharp {
                fixed(width: 415, height: 375) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
